<template>
  <div class="grid grid-nogutter">
    <div class="col-10 p-fluid col-offset-1">
      <InputText
        type="text"
        :disabled="scheme.status"
        v-model="v$.instruction.$model"
        @change="setInstructionRule(index)"
        :class="{
          'p-invalid': v$.instruction.$invalid && submitted,
        }"
        class="align-self-end"
        :placeholder="$t('schemes.instructionRule') + ' *'"
      />

      <!-- <small
        v-if="(v$.instruction.$invalid && submitted) || v$.$response"
        class="p-error"
        >{{
          v$.instruction.required.$message.replace(
            "[value]",
            $t("schemes.instructionRule")
          )
        }}</small
      > -->
    </div>
    <div class="col-1 text-center">
      <Button
        icon="mdi mdi-close-circle mdi-18px"
          :disabled="scheme.status"
        class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
        @click="deleteInstructionRule(index)"
      />
    </div>
      <ul
              v-if="v$.instruction.$error && submitted"
              class="p-error text-xs col-10 col-offset-1 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.instruction
                  .$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.instructionRule")
                  )
                }}
              </li>
            </ul>
  </div>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Instruction rule",
  props: [
    "instructionsForAssessmentValue",
    "indexValue",
    "submittedValue",
    "instructionValue",
    "schemeValue",
  ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      instruction: this.instructionValue,
    };
  },
  validations() {
    return {
      instruction: {
        required,
      },
    };
  },
  watch: {
    async submitted(val) {
      if (val == true) {
        await this.v$.$validate();
      }
    },
    instruction() {
      return this.instructionValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  computed: {
    instructionsForAssessment() {
      return this.instructionsForAssessmentValue;
    },
    index() {
      return this.indexValue;
    },
    submitted() {
      return this.submittedValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  methods: {
    deleteInstructionRule(index) {
      this.instructionsForAssessment.splice(index, 1);
    },
    setInstructionRule(index) {
      this.scheme.instructionsForAssessment.splice(index, 1, this.instruction);
    },
  },
};
</script>

