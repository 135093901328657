<template>
  <AddScheme
    :display="addSchemeVisible"
    @closeAddSchemeDialog="closeAddScheme"
    :schemeId="schemeId"
    @editOpened="stopButtonLoading"
    :schemeValue="schemeValue"
  ></AddScheme>
  <FileUpload
    hidden
    name="schemeUpload"
    :customUpload="true"
    @uploader="uploadScheme"
    :multiple="selectMultiple"
    :maxFileSize="$fileSize"
    @select="upload"
    :chooseLabel="$t('global.choose')"
    :uploadLabel="$t('global.upload')"
  >
  </FileUpload>
  <div>
    <Card>
      <template #content>
        <div class="grid">
          <div class="col-6 md:col-2">
            <h4>{{ $t("schemes.title") }}</h4>
          </div>
          <div class="text-center text-right col-6 md:col-2">
            <Button
              v-if="!isMobile"
              :label="$t('global.export')"
              @click="exportCSV"
            ></Button>
          </div>
          <div class="col-6 md:col-2 text-left md:text-right">
            <Button
              v-if="!isMobile"
              :label="$t('settings.bulkDownload')"
              @click="exportJson"
            ></Button>
          </div>
          <div class="col-6 md:col-2 text-right">
            <Button
              :label="$t('settings.bulkAddition')"
              @click="selectMultipleSchemes"
            ></Button>
          </div>
          <div class="col-6 text-center md:col-2 text-left md:text-right">
            <Button
              :label="$t('schemes.uploadScheme')"
              @click="selectScheme"
              iconPos="right"
              :loading="loadingUpload"
            />
          </div>
          <div class="col-6 text-center md:col-2 text-right">
            <Button :label="$t('schemes.addScheme')" @click="viewAddScheme" />
          </div>
        </div>
        <DataTable
          :value="certCategories"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :loading="loading"
          :filters="filters"
          ref="schemesTable"
          showGridlines
          class="p-datatable-schemes"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          :currentPageReportTemplate="$tableString"
          removableSort
          sortField="createdDate"
          :sortOrder="-1"
          :rowClass="deletedScheme"
        >
          <template #header>
            <div>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  :placeholder="$t('global.search')"
                />
              </span>
            </div>
          </template>
          <template #empty>{{ $t("schemes.noSchemesFound") }}</template>
          <Column field="id" :header="$t('schemes.schemeId')" :sortable="true">
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column
            field="createdDate"
            :header="$t('schemes.creationDate')"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ $dayjs(data.tempCreatedDate).format("DD/MM/YYYY") }}
            </template>
          </Column>
          <Column
            field="title"
            :header="$t('schemes.certificationName')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="text-right">{{ data.title }}</span>
            </template>
          </Column>
          <Column
            field="certificationType"
            :header="$t('schemes.certificationType')"
            :sortable="true"
          >
            <template #body="{ data }">
              <div class="text-right md:text-left">
                {{ data.schemeTitle }}
              </div>
            </template>
          </Column>
          <Column field="status" :header="$t('global.status')" :sortable="true">
            <template #body="{ data }">
              {{
                data.deleted
                  ? $t("schemes.deleted")
                  : translatedStatus(data.status)
              }}
            </template>
          </Column>
          <Column
            field="Actions"
            :header="$t('global.actions')"
            :exportable="false"
          >
            <template #body="{ data, index }">
              <div class="flex justify-content-center">
                <Button
                  :label="$t('global.edit')"
                  class="p-button-success"
                  @click="editScheme(data.id, index)"
                  iconPos="right"
                  :loading="loadingEdit && buttonIndex == index"
                  :disabled="data.deleted"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AddScheme from "./CreateEditScheme.vue";
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";
// import { nextTick } from "vue-demi";

export default {
  components: {
    AddScheme,
  },
  name: "Shcemes",
  data() {
    return {
      addSchemeVisible: false,
      schemeId: null,
      loading: true,
      loadingEdit: false,
      buttonIndex: false,
      buttonElementUpload: "",
      buttonElementSelect: "",
      buttonElementCancel: "",
      schemeValue: {},
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loadingUpload: false,
      selectMultiple: false,
    };
  },
  computed: {
    ...mapGetters([
      "certCategories",
      "getScheme",
      "checkPermission",
      "checkRole",
      "translatedStatus",
      "isMobile",
    ]),
  },
  mounted() {
    this.getSchemes();
    this.$action("schemes-page-view");
    this.buttonElementUpload = document.querySelectorAll(
      ".p-fileupload-buttonbar button"
    )[0];
    this.buttonElementSelect = document.querySelector(".p-fileupload-choose");
    this.buttonElementCancel = document.querySelectorAll(
      ".p-fileupload-buttonbar button"
    )[1];
  },
  methods: {
    selectScheme() {
      this.selectMultiple = false;
      this.$nextTick(() => {
        this.buttonElementSelect.click();
      });
    },
    upload() {
      this.$nextTick(() => {
        this.buttonElementUpload.click();
      });
    },
    async uploadScheme(event) {
      this.loadingUpload = true;

      event.files.map((file) => {
        const reader = new FileReader();
        reader.addEventListener("load", async (ev) => {
          const schemeValue = JSON.parse(ev.target.result);
          await this.$store
            .dispatch("createScheme", schemeValue)
            .then((res) => {
              this.$action("certification-scheme-create", { schemeId: res.id });
              this.$notification(
                this.$t("schemes.addScheme"),
                this.$t("schemes.schemeCreated")
              );
              this.loading = true;
              this.getSchemes();
              this.loadingUpload = false;
              this.$nextTick(() => {
                this.buttonElementCancel.click();
              });
            })
            .catch(() => {
              this.loadingUpload = false;
              this.$nextTick(() => {
                this.buttonElementCancel.click();
              });
            });
        });

        reader.readAsText(file);
      });
    },
    exportCSV() {
      this.$refs.schemesTable.exportCSV();
      this.$action("export-schemes");
    },
    viewAddScheme() {
      this.addSchemeVisible = true;
    },
    closeAddScheme(val) {
      this.addSchemeVisible = false;
      this.schemeId = null;
      this.schemeValue = {};
      console.log(val);
      if (val == true) {
        this.loading = true;
        this.getSchemes();
      }
    },
    async editScheme(id, index) {
      // this.loadingEdit = true;
      this.buttonIndex = index;
      this.schemeId = id;
      this.schemeValue = this.getScheme(this.schemeId);
      this.viewAddScheme();
      console.log(this.schemeId);
      this.$action("certification-scheme-view");
    },
    stopButtonLoading() {
      console.log("call closed");
      this.loadingEdit = false;
      this.buttonIndex = null;
    },
    async getSchemes() {
      await this.$store.dispatch("schemes");
      this.loading = false;
    },
    exportJson() {
      this.certCategories.forEach((scheme) => {
        this.$store.dispatch("exportJson", {
          name: scheme.schemeTitle,
          json: scheme,
        });
      });
    },
    selectMultipleSchemes() {
      this.selectMultiple = true;
      this.$nextTick(() => {
        this.buttonElementSelect.click();
      });
    },
    deletedScheme(data) {
      return data.deleted ? "deleted-scheme" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.deleted-scheme) {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
</style>
