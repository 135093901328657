<template>
  <div class="grid grid-nogutter">
    <div class="col-9 p-fluid">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon bg-green-500 text-white-alpha-90">
          {{ scoreItemIndex + 1 }}.{{ itemIndex + 1 }}
        </span>
        <!-- <InputText
          type="text"
          :disabled="scheme.status"
          :placeholder="$t('schemes.itemLabel') + ' *'"
          v-model="v$.item.title.$model"
          :class="{
            'p-invalid': v$.item.title.$invalid && submitted,
          }"
        /> -->
         <Textarea
            :disabled="scheme.status"
            v-model="v$.item.title.$model"
            rows="1"
            :placeholder="$t('schemes.itemLabel') + ' *'"
           :class="{
            'p-invalid': v$.item.title.$invalid && submitted,
          }"
          />
      </div>

      <!-- <small
        v-if="
          (v$.item.title.$invalid && submitted) ||
          v$.item.title.$pending.$response
        "
        class="p-error"
        >{{
          v$.item.title.required.$message.replace(
            "[value]",
            $t("schemes.itemLabel")
          )
        }}</small
      > -->
    </div>
    <div class="col-2 p-fluid">
      <InputText
        :disabled="scheme.status"
        v-model="v$.item.points.$model"
        style="margin-left: 5%"
        type="number"
        readonly="true"
      />
    </div>
    <div class="col-1 text-center">
      <Button
        :disabled="scheme.status"
        icon="mdi mdi-close-circle mdi-18px"
        class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
        @click="deleteItem(scoreItemIndex, itemIndex)"
      />
    </div>
    <ul
      v-if="v$.item.title.$error && submitted"
      class="p-error text-xs col-9 mt-0 pl-3"
    >
      <li v-for="(error, index) of v$.item.title.$errors" :key="index">
        {{ error.$message.replace("[value]", $t("schemes.itemLabel")) }}
      </li>
    </ul>
  </div>

  <div class="grid grid-nogutter mt-3">
    <div class="col-0 md:col-2 md:border-right-1 border-blue-500"></div>
    <span
      class="col-12 md:col-10 text-lg font-bold pb-2 align-self-center pl-2 border-left-3 md:border-none border-blue-500"
    >
      {{ $t("schemes.assessments") }}
    </span>

    <div
      class="col-12"
      v-for="(assessment, assessmentIndex) in item.assessments"
      :key="assessmentIndex"
    >
      <div class="grid grid-nogutter">
        <div class="col-0 md:col-2 md:border-right-1 border-blue-500"></div>
        <div class="col-12 md:col-10 md:pl-2">
          <Assessments
            :assessmentsValue="item.assessments"
            :itemIndexValue="itemIndex"
            :scoreItemIndexValue="scoreItemIndex"
            :assessmentIndexValue="assessmentIndex"
            :scoreItemsValue="scoreItems"
            :assessmentValue="assessment"
            :submittedValue="submitted"
            :schemeValue="scheme"
          ></Assessments>
        </div>
      </div>
    </div>
    <div class="col-0 md:col-2"></div>
    <div class="col-12 md:col-10 text-left align-self-center mb-3 md:mb-0">
      <Button
        :disabled="scheme.status"
        class="p-button-sm bg-blue-500 border-none"
        icon="mdi mdi-plus-circle-outline"
        iconPos="left"
        :label="$t('schemes.addAssessment')"
        @click="addAssessment(scoreItemIndex, itemIndex)"
      />
    </div>
  </div>
</template>

<script>
import Assessments from "./Assessments.vue";
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Items",
  components: { Assessments },
  setup: () => ({ v$: useVuelidate() }),
  props: [
    "itemsValue",
    "itemIndexValue",
    "scoreItemIndexValue",
    "scoreItemsValue",
    "itemValue",
    "submittedValue",
    "schemeValue",
  ],
  data() {
    return {};
  },
  validations() {
    return {
      item: {
        title: {
          required,
        },
        points: {
          required,
        },
      },
    };
  },
  watch: {
    async submitted(val) {
      if (val == true) {
        await this.v$.$validate();
      }
    },
    item() {
      return this.itemValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  computed: {
    items() {
      return this.itemsValue;
    },
    item() {
      return this.itemValue;
    },
    itemIndex() {
      return this.itemIndexValue;
    },
    scoreItemIndex() {
      return this.scoreItemIndexValue;
    },
    scoreItems() {
      return this.scoreItemsValue;
    },
    submitted() {
      return this.submittedValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  methods: {
    deleteItem(scoreItemIndex, itemIndex) {
      this.scoreItems[scoreItemIndex].items.splice(itemIndex, 1);
    },
    addAssessment(scoreItemIndex, itemIndex) {
      this.scoreItems[scoreItemIndex].items[itemIndex].assessments.push({
        title: "",
        points: 0,
        criteria: [
          {
            title: "",
            scoreRatio: 0,
          },
        ],
      });
    },
  },
};
</script>
