<template>
  <div class="grid grid-nogutter">
    <div class="col-9 p-fluid">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon bg-blue-500 text-white-alpha-90">
          {{ scoreItemIndex + 1 }}.{{ itemIndex + 1 }}.{{ assessmentIndex + 1 }}
        </span>
        <!-- <InputText
          type="text"
          :disabled="scheme.status"
          :placeholder="$t('schemes.assessmentLabel') + ' *'"
          v-model="v$.assessment.title.$model"
          :class="{
            'p-invalid': v$.assessment.title.$invalid && submitted,
          }"
        /> -->
        <Textarea
          :disabled="scheme.status"
          rows="1"
          :placeholder="$t('schemes.assessmentLabel') + ' *'"
          v-model="v$.assessment.title.$model"
          :class="{
            'p-invalid': v$.assessment.title.$invalid && submitted,
          }"
        />
      </div>
      <!-- <small
        v-if="
          (v$.assessment.title.$invalid && submitted) ||
          v$.assessment.title.$pending.$response
        "
        class="p-error"
        >{{
          v$.assessment.title.required.$message.replace(
            "[value]",
            $t("schemes.assessmentLabel")
          )
        }}</small
      > -->
    </div>
    <div class="col-2 p-fluid">
      <InputNumber
        :disabled="scheme.status"
        v-model="v$.assessment.points.$model"
        style="margin-left: 5%"
        :min="0"
        mode="decimal"
        :minFractionDigits="0" 
        :maxFractionDigits="2"
      />
    </div>
    <div class="col-1 text-center">
      <Button
        :disabled="scheme.status"
        icon="mdi mdi-close-circle mdi-18px"
        class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
        @click="deleteAssessment(scoreItemIndex, itemIndex, assessmentIndex)"
        @change="deleteAssessment(scoreItemIndex, itemIndex, assessmentIndex)"
      />
    </div>
    <ul
      v-if="v$.assessment.title.$error && submitted"
      class="p-error text-xs col-12 mt-0 pl-3"
    >
      <li v-for="(error, index) of v$.assessment.title.$errors" :key="index">
        {{ error.$message.replace("[value]", $t("schemes.assessmentLabel")) }}
      </li>
    </ul>
  </div>
  <div class="grid">
    <div class="col-12">
      <template v-if="scheme.status">
        <span v-html="assessment.remarks"></span>
      </template>
      <template v-else>
        <Editor v-model="assessment.remarks" editorStyle="height: 100px" />
      </template>
    </div>
  </div>
  <div class="grid grid-nogutter">
    <div class="col-0 md:col-3 md:border-right-1 border-purple-500"></div>
    <span
      class="col-12 md:col-9 text-lg font-bold pb-2 align-self-center pl-2 border-left-3 md:border-none border-purple-500"
    >
      {{ $t("schemes.criteria") }}
    </span>

    <div
      class="col-12"
      v-for="(criteria, criteriaIndex) in assessment.criteria"
      :key="criteriaIndex"
    >
      <div class="grid grid-nogutter">
        <div class="col-0 md:col-3 md:border-right-1 border-purple-500"></div>

        <Criteria
          class="col-12 md:col-9 md:pl-2"
          :criteriasValue="assessment.criteria"
          :itemIndexValue="itemIndex"
          :scoreItemIndexValue="scoreItemIndex"
          :assessmentIndexValue="assessmentIndex"
          :criteriaIndexValue="criteriaIndex"
          :scoreItemsValue="scoreItems"
          :assessmentValue="assessment"
          :criteriaValue="criteria"
          :submittedValue="submitted"
          :schemeValue="scheme"
        ></Criteria>
      </div>
    </div>
    <div class="col-0 md:col-3"></div>
    <div class="col-12 md:col-9 text-left align-self-center mb-3">
      <Button
        :disabled="scheme.status"
        :label="$t('schemes.addCriteria')"
        icon="mdi mdi-plus-circle-outline"
        iconPos="left"
        class="p-button-sm bg-purple-500 border-none"
        @click="addCriteria()"
      />
    </div>
  </div>
</template>

<script>
import Criteria from "./Criteria.vue";
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Assessments",
  components: { Criteria },
  setup: () => ({ v$: useVuelidate() }),
  props: [
    "assessmentsValue",
    "itemIndexValue",
    "scoreItemIndexValue",
    "assessmentIndexValue",
    "scoreItemsValue",
    "assessmentValue",
    "submittedValue",
    "schemeValue",
  ],
  data() {
    return {};
  },
  validations() {
    return {
      assessment: {
        title: {
          required,
        },
        points: {
          required,
        },
      },
    };
  },
  watch: {
    async submitted(val) {
      if (val == true) {
        await this.v$.$validate();
      }
    },
    assessment() {
      return this.assessmentValue;
    },
    scoreItems: {
      deep: true,
      handler() {
        this.scoreItems.forEach((el) => {
        let scoreItemSum = 0;
        el.items.forEach((el1) => {
          let itemSum = 0;
          el1.assessments.forEach((el2) => {
            // if (parseInt(el2.points)) {
              itemSum += el2.points;
            // }
          });
          el1.points = itemSum;
          scoreItemSum += itemSum;
        });
        el.points = scoreItemSum;
      });
      }
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  computed: {
    assessments() {
      return this.assessmentsValue;
    },
    itemIndex() {
      return this.itemIndexValue;
    },
    scoreItemIndex() {
      return this.scoreItemIndexValue;
    },
    assessmentIndex() {
      return this.assessmentIndexValue;
    },
    scoreItems() {
      return this.scoreItemsValue;
    },
    assessment() {
      return this.assessmentValue;
    },
    submitted() {
      return this.submittedValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  methods: {
    deleteAssessment(scoreItemIndex, itemIndex, assessmentIndex) {
      this.scoreItems[scoreItemIndex].items[itemIndex].assessments.splice(
        assessmentIndex,
        1
      );
    },
    addCriteria() {
      //       this.scoreItems[scoreItemIndex].items[itemIndex].assessments[
      //         assessmentIndex
      //       ].criteria.push({
      //         title: "",
      //         scoreRatio: 0,
      //       });
      this.assessment.criteria.push({
        title: "",
        scoreRatio: 0,
      });
    },
  //   updatePoints(val) {
  //     // let data = this.scheme.scoreItems[scoreItemIndex].items[itemIndex].assessments[assessmentIndex].points;
  //     // if(data > 0) {
  //     //   this.scheme.scoreItems[scoreItemIndex].points += data;
  //     // this.scheme.scoreItems[scoreItemIndex].items[itemIndex].points += data;
  //     // }
  // console.log(val);
  //     this.scoreItems.forEach((el) => {
  //       let scoreItemSum = 0;
  //       el.items.forEach((el1) => {
  //         let itemSum = 0;
  //         el1.assessments.forEach((el2) => {
  //           // if (parseInt(el2.points)) {
  //             console.log('run inside')
  //             console.log(el2);
  //             console.log(el2.points)
  //             console.log(typeof el2.points);
  //             itemSum += el2.points;
  //           // }
  //         });
  //         console.log('run outside')
  //         console.log(itemSum);
  //         el1.points = itemSum;
  //         scoreItemSum += itemSum;
  //       });
  //       el.points = scoreItemSum;
  //     });
  //     console.log(this.scoreItems)

  //     // console.log(data);
  //     //       console.log(scoreItemIndex, itemIndex, assessmentIndex);
  //     //       console.log(event.value);
  //   },
  },
};
</script>
