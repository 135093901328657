<template>
  <div class="grid grid-nogutter">
    <div class="col-9 p-fluid">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon bg-purple-500 text-white-alpha-90">
          {{ scoreItemIndex + 1 }}.{{ itemIndex + 1 }}.{{
            assessmentIndex + 1
          }}.{{ criteriaIndex + 1 }}
        </span>
        <!-- <InputText
          type="text"
          :disabled="scheme.status"
          :placeholder="$t('schemes.criteriaLabel') + ' *'"
          v-model="v$.criteria.title.$model"
          :class="{
            'p-invalid': v$.criteria.title.$invalid && submitted,
          }"
        /> -->
        <Textarea
            :disabled="scheme.status"
            rows="1"
            :placeholder="$t('schemes.criteriaLabel') + ' *'"
           v-model="v$.criteria.title.$model"
          :class="{
            'p-invalid': v$.criteria.title.$invalid && submitted,
          }"
          />
      </div>
      <!-- <small
        v-if="
          (v$.criteria.title.$invalid && submitted) ||
          v$.criteria.title.$pending.$response
        "
        class="p-error"
        >{{
          v$.criteria.title.required.$message.replace(
            "[value]",
            $t("schemes.criteriaLabel")
          )
        }}</small
      > -->
    </div>
    <div class="col-2 p-fluid">
      <InputNumber
        :disabled="scheme.status"
        v-model="v$.criteria.scoreRatio.$model"
        :class="{
          'p-invalid': v$.criteria.scoreRatio.$invalid && submitted,
        }"
        aria-describedby="roleName-error"
        style="margin-left: 5%"
        placeholder="Item points"
        :min="-1"
        :max="100"
        :suffix="v$.criteria.scoreRatio.$model == -1 ? '' : '%'"
      />
    </div>
    <div class="col-1 text-center">
      <Button
        :disabled="scheme.status"
        icon="mdi mdi-close-circle mdi-18px"
        class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
        @click="
          deleteCriteria(
            scoreItemIndex,
            itemIndex,
            assessmentIndex,
            criteriaIndex
          )
        "
      />
    </div>
    <ul
      v-if="v$.criteria.title.$error && submitted"
      class="p-error text-xs col-12 pl-3 mt-0"
    >
      <li v-for="(error, index) of v$.criteria.title.$errors" :key="index">
        {{ error.$message.replace("[value]", $t("schemes.criteriaLabel")) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "Criteria",
  setup: () => ({ v$: useVuelidate() }),
  props: [
    "criteriasValue",
    "itemIndexValue",
    "scoreItemIndexValue",
    "assessmentIndexValue",
    "criteriaIndexValue",
    "scoreItemsValue",
    "assessmentValue",
    "submittedValue",
    "criteriaValue",
    "schemeValue",
  ],
  data() {
    return {};
  },
  validations() {
    return {
      criteria: {
        title: {
          required,
        },
        scoreRatio: {
          required,
        },
      },
    };
  },
  watch: {
    async submitted(val) {
      if (val == true) {
        await this.v$.$validate();
      }
    },
    criteria() {
      return this.criteriaValue;
    },
  },
  computed: {
    criteria() {
      return this.criteriaValue;
    },
    criterias() {
      return this.criteriasValue;
    },
    itemIndex() {
      return this.itemIndexValue;
    },
    scoreItemIndex() {
      return this.scoreItemIndexValue;
    },
    assessmentIndex() {
      return this.assessmentIndexValue;
    },
    criteriaIndex() {
      return this.criteriaIndexValue;
    },
    scoreItems() {
      return this.scoreItemsValue;
    },
    assessment() {
      return this.assessmentValue;
    },
    submitted() {
      return this.submittedValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  methods: {
    deleteCriteria(scoreItemIndex, itemIndex, assessmentIndex, criteriaIndex) {
      this.scoreItems[scoreItemIndex].items[itemIndex].assessments[
        assessmentIndex
      ].criteria.splice(criteriaIndex, 1);
    },
  },
};
</script>
