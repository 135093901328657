<template>
  <div class="col-12 mb-3">
    <div class="grid grid-nogutter">
      <div class="col-9 p-fluid">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon bg-orange-500 text-white-alpha-90">
            {{ scoreItemIndex + 1 }}
          </span>
          <!-- <InputText
            type="text"
            :disabled="scheme.status"
            v-model="v$.scoreItem.title.$model"
            :placeholder="$t('schemes.scoreItemLabel') + ' *'"
            :class="{
              'p-invalid': v$.scoreItem.title.$invalid && submitted,
            }"
          /> -->
          <Textarea
            :disabled="scheme.status"
            v-model="v$.scoreItem.title.$model"
            rows="1"
            :placeholder="$t('schemes.scoreItemLabel') + ' *'"
            :class="{
              'p-invalid': v$.scoreItem.title.$invalid && submitted,
            }"
          />
        </div>
        <!-- <small
          v-if="
            (v$.scoreItem.title.$invalid && submitted) ||
            v$.scoreItem.title.$pending.$response
          "
          class="p-error"
          >{{
            v$.scoreItem.title.required.$message.replace(
              "[value]",
              $t("schemes.scoreItemLabel")
            )
          }}</small
        > -->
      </div>
      <div class="col-2 p-fluid">
        <InputText
          v-model.number="v$.scoreItem.points.$model"
          :disabled="scheme.status"
          style="margin-left: 5%"
          type="number"
          readonly="true"
        />
      </div>
      <div class="col-1 text-center">
        <Button
          :disabled="scheme.status"
          icon="mdi mdi-close-circle mdi-18px"
          class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
          @click="deleteScoreItem(scoreItemIndex)"
        />
      </div>
      <ul
        v-if="v$.scoreItem.title.$error && submitted"
        class="p-error text-xs col-9 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.scoreItem.title.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("schemes.scoreItemLabel")) }}
        </li>
      </ul>
    </div>
  </div>
  <div class="col-0 md:col-1 md:border-right-1 border-green-500"></div>
  <span
    class="col-12 md:col-11 text-lg font-bold pb-2 align-self-center pl-2 mb-0 mt-0 border-left-3 md:border-none border-green-500"
  >
    {{ $t("schemes.items") }}
  </span>

  <!-------------------------Items----------------------->

  <div
    class="col-12"
    v-for="(item, itemIndex) in scoreItem.items"
    :key="itemIndex"
  >
    <div class="grid grid-nogutter">
      <div class="col-0 md:col-1 md:border-right-1 border-green-500"></div>

      <div class="col-12 md:col-11 md:pl-2">
        <Items
          :itemsValue="scoreItem.items"
          :itemIndexValue="itemIndex"
          :itemValue="item"
          :scoreItemIndexValue="scoreItemIndex"
          :scoreItemsValue="scoreItems"
          :submittedValue="submitted"
          :schemeValue="scheme"
        ></Items>
      </div>
    </div>
  </div>
  <div class="col-0 md:col-1"></div>
  <div class="col text-left align-self-center mb-3">
    <Button
      class="p-button-sm bg-green-500 border-none"
      :disabled="scheme.status"
      icon="mdi mdi-plus-circle-outline"
      iconPos="left"
      :label="$t('schemes.addItem')"
      @click="addItem(scoreItemIndex, itemIndex)"
    />
  </div>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import Items from "./Items.vue";
export default {
  name: "Score items",
  components: {
    Items,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: [
    "scoreItemValue",
    "scoreItemsValue",
    "indexValue",
    "submittedValue",
    "schemeValue",
  ],
  data() {
    return {};
  },
  validations() {
    return {
      scoreItem: {
        title: {
          required,
        },
        points: {
          required,
        },
      },
    };
  },
  watch: {
    async submitted(val) {
      if (val == true) {
        await this.v$.$validate();
      }
    },
    scoreItem() {
      return this.scoreItemValue;
    },
  },
  computed: {
    scoreItem() {
      this.updateTotalPoints();
      return this.scoreItemValue;
    },
    scoreItems() {
      return this.scoreItemsValue;
    },
    scoreItemIndex() {
      return this.indexValue;
    },
    submitted() {
      return this.submittedValue;
    },
    scheme() {
      return this.schemeValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  methods: {
    deleteScoreItem(index) {
      this.scoreItems.splice(index, 1);
    },
    addItem(index) {
      this.scoreItems[index].items.push({
        title: "",
        points: 0,
        assessments: [
          {
            title: "",
            points: 0,
            criteria: [
              {
                title: "",
                scoreRatio: 0,
              },
            ],
          },
        ],
      });
    },
    updateTotalPoints() {
      let total = 0;
      this.scoreItems.forEach((el) => {
        total += el.points;
      });
      console.log(total);
      this.scheme.totalPoints = total;
    },
  },
};
</script>
